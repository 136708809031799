import { navigate } from 'gatsby';
import * as React from 'react';
import tw, { styled } from 'twin.macro';
import InsightCardTemplate from './InsightCardTemplate';

const StyledContainer = styled.section(() => [tw`relative z-10 max-w-screen-lg xl:max-w-screen-xl mx-auto`]);

const OurValue = styled.div(() => [tw`pt-10 pb-10 md:pb-32 px-6 md:pt-10 sm:mb-20 md:px-8 md:mb-14 xl:mb-0`]);

const Back = styled.p(() => [
  tw`flex w-full text-[20px] text-left font-normal text-spaceGray mt-0 mb-8 sm:mb-10 select-none cursor-pointer `
]);

const WorkContainer = styled.div(() => [
  tw`grid grid-cols-1 max-w-full gap-x-[100px] gap-y-4 md:gap-y-[60px] bg-transparent`
]);

export const Background = styled.div(() => [tw`absolute top-0 h-[785px] w-screen background-color[#E46B25] `]);

interface InsightDetailProps {
  className?: string;
  data: any;
}

const InsightDetail: React.FC<InsightDetailProps> = ({ className, data }) => {
  return (
    <>
      <StyledContainer className={className}>
        <OurValue>
          <Back onClick={() => navigate(-1)}>{'< Back to insights'}</Back>
          <WorkContainer>
            <InsightCardTemplate data={data} />
          </WorkContainer>
        </OurValue>
      </StyledContainer>
    </>
  );
};

export default InsightDetail;
