import * as React from 'react'
import tw, { styled } from 'twin.macro'
import { navigate } from 'gatsby'
import { marked } from 'marked'

const InsightBox = styled.div(() => [tw`grid grid-cols-2 gap-10 mb-10 md:mb-14`])

const InsightImage = styled.img(() => [
  tw`flex justify-center items-center self-start w-full h-[221px] md:h-[653px] rounded-[10px] object-cover overflow-hidden col-span-2 md:col-span-1 mb-[32px] md:mb-[52px]`
])

const InsightDetail = styled.div(() => [tw`flex flex-col justify-between col-span-2 md:col-span-2`])

const PublishedRow = styled.div(() => [tw`flex flex-row items-center mb-4 md:mb-[25px]`])

const AuthorDisplay = styled.img(() => [tw`w-6 h-6 rounded-full mr-2`])

const PublishedDetail = styled.p(() => [tw`text-[14px] font-bold text-orange mr-2 mb-0`])

const Dot = styled.div(() => [tw`w-1 h-1 bg-orange mr-2 rounded-full`])

const ContentTypeMobile = styled.p(() => [tw`md:hidden text-xl md:text-2xl font-bold text-ociDarkGrey mb-2 md:mb-3`])

const ContentType = styled.p(() => [tw`hidden md:block text-xl md:text-2xl  font-bold text-ociDarkGrey mb-2 md:mb-3`])

const Title = styled.h4(() => [
  tw`font-extrabold text-[40px] text-spaceGray text-left mt-0 cursor-pointer mb-6 md:mb-8 hidden md:block`
])

const TitleMobile = styled.h4(() => [
  tw`font-extrabold text-2xl md:text-[40px] text-spaceGray text-left mt-0 cursor-pointer mb-4 block md:hidden`
])

const RichText = styled.div(() => [
  tw`font-normal text-[#283941] text-[14px] md:text-xl mb-6 leading-5 md:leading-7 font-sans whitespace-pre-line`
])

interface Props {
  className?: string
  data: InsightsData
}

export interface InsightsData {
  id: number
  author: string
  profile: any
  updatedAt: Date
  minRead: string
  contentType: string
  title: string
  excerpt: string
  thumbnail: any
  client: string
  detail: string
}

const checkUnd = (value: any) => {
  if (!value || value === '') {
    return false
  }
  return true
}

const formatDate = (dateTime: Date) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  if (dateTime) {
    const month = months[dateTime.getMonth()]
    return `${month} ${dateTime.getDate()} ${dateTime.getFullYear()}`
  }
  return undefined
}

const getMarkdownText = (markdown: string) => {
  const rawMarkup = marked.parse(markdown)
  return { __html: rawMarkup }
}

const InsightCardTemplate: React.FC<Props> = ({ className, data }) => (
  <InsightBox className={className}>
    <InsightDetail>
      <div>
        <TitleMobile>{data.title}</TitleMobile>
        <ContentTypeMobile>{data.contentType}</ContentTypeMobile>
        <PublishedRow>
          {checkUnd(data.profile) && <AuthorDisplay src={data.profile} />}
          {checkUnd(data.author) && <PublishedDetail>{data.author} </PublishedDetail>}
          {checkUnd(data.author) && <Dot />}
          <PublishedDetail>{formatDate(new Date(data.updatedAt))} </PublishedDetail>
          <Dot />
          <PublishedDetail>{data.minRead} min read</PublishedDetail>
        </PublishedRow>
        <ContentType>{data.contentType}</ContentType>
        <Title onClick={() => navigate(`/insights/${data.client}`)}>
          {data.title}
          <br />
        </Title>
        <InsightImage src={data.thumbnail} />
        <RichText dangerouslySetInnerHTML={getMarkdownText(data?.detail)} />
      </div>
    </InsightDetail>
  </InsightBox>
)

export default InsightCardTemplate
