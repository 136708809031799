import * as React from 'react';
import tw, { styled } from 'twin.macro';
import OtherInsightsCard, { WorkCardData } from './OtherInsightsCard';

const StyledContainer = styled.section(() => [
  tw`relative h-auto ml-auto mr-auto bg-[#F3F3F4] max-w-full py-6 md:py-10`
]);

// const Column = styled.div(() => [tw`pb-8 md:pb-18 flex justify-center overflow-x-auto`])

// const Box = styled.div(() => [tw`gap-x-12 flex justify-center flex-col mx-6 md:flex-row md:mx-32`])

const Title = styled.p(() => [tw`flex w-full text-2xl md:text-[32px] justify-center font-bold text-spaceGray mb-16`]);

interface RelatedArticlesProps {
  className?: string;
  all: any;
}

const RelatedArticles: React.FC<RelatedArticlesProps> = ({ className, all }) => (
  <StyledContainer className={className}>
    <Title>Related articles</Title>
    <div className="mx-auto xl:max-w-screen-xl grid gap-12 px-6 md:px-8 overflow-auto md:grid-flow-col">
      {all && all.slice(0, 4).map((list: { node: WorkCardData; }) => <OtherInsightsCard key={list.node.id} data={list.node} />)}
    </div>
    {/* <Column>
      <Box>
        {all && all.slice(0, 4).map((list: { node: WorkCardData }) => <OtherInsightsCard data={list.node} />)}
      </Box>
    </Column> */}
  </StyledContainer>
);

export default RelatedArticles;
