import * as React from 'react'
import Page from '../components/Common/Page'
import InsightDetail from '../components/pages/InsightPage/InsightDetail'
import RelatedArticles from '../components/pages/InsightPage/RelatedArticles'
import IndexLayout from '../layouts'

const InsightsTemplate: React.FC<any> = ({ pageContext: { data, all } }) => (
  <IndexLayout
    transparent
    meta={[
      { property: 'og:image', content: data.thumbnail },
      { property: 'og:title', content: data.title },
      ...(data.excerpt ? [{ property: 'og:description', content: data.excerpt }] : []),
    ]}
  >
    <Page>
      <InsightDetail data={data} />
      <RelatedArticles all={all} />
    </Page>
  </IndexLayout>
)

export default InsightsTemplate
