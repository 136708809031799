import * as React from 'react'
import tw, { styled } from 'twin.macro'
import { navigate } from 'gatsby'

const WorkBox = styled.div(() => [
  tw`col-span-1 flex flex-col justify-start items-start sm:justify-start md:min-w-[280px] md:max-w-[25%]`
])

const ImageContainer = styled.img(() => [
  tw`flex justify-center items-center self-start w-full h-[160px] md:h-[184px] rounded-[10px] mb-6 cursor-pointer object-cover overflow-hidden hover:opacity-80`
])

const ValueTitle = styled.h4(() => [tw`font-extrabold text-lg md:text-xl text-spaceGray text-left mt-0 cursor-pointer`])

const LowerValue = styled.span(() => [tw`font-medium text-spaceGray cursor-pointer`])

const ContentType = styled.p(() => [tw`text-[16px] md:text-xl font-bold text-ociDarkGrey mb-0`])

interface Props {
  className?: string
  data: WorkCardData
}
export interface WorkCardData {
  id: string
  title: string
  thumbnail: string
  client: string
  contentType: string
  constentType: string
}

const OtherInsightsCard: React.FC<Props> = ({ className, data }) => (
  <WorkBox className={className}>
    <ImageContainer onClick={() => navigate(`/insights/${data.client}`)} src={data.thumbnail} />
    <ValueTitle onClick={() => navigate(`/insights/${data.client}`)}>
      {data.title}
      <br />
      <ContentType>{data.contentType}</ContentType>
    </ValueTitle>
    <LowerValue onClick={() => navigate(`/insights/${data.client}`)}>{data.constentType}</LowerValue>
  </WorkBox>
)

export default OtherInsightsCard
